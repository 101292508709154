import './css/login.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

function Login() {
    let navigate = useNavigate()
    const [number, setNumber] = useState("")

    const onChangeNumber = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        // if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
        //     onlyNumber = onlyNumber.substring(1);
        // }
        setNumber(onlyNumber);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            Check_Mobile()
        };
    }

    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const Check_Mobile = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/login', {
            withCredentials: true,
            params: {
                id: number.startsWith('0') ? number.substring(1) : number,
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                navigate(`/authentication`, {
                    state: {
                        id: number.startsWith('0') ? number.substring(1) : number
                    }
                })
            }
            else {
                alert(tmp.m)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="App-box-1">
            <div className='login-1'>
                <img src='/img/GOEX_logo_light.png' />
                <div className='login-1-1'>Broker</div>
                {/* <div className='login-1-1-1'>- broker</div> */}
                <input className='login-1-1-input' placeholder='모바일 번호를 입력해주세요.' value={number} onChange={onChangeNumber} onKeyDown={handleKeyPress} ref={inputRef} />
            </div>

            <div className='login-2'>
                <div className='login-2-1 btn' onClick={() => Check_Mobile()}>다음</div>
            </div>
        </div>
    )
}

export default Login;