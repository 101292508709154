import '../css/login.css'
import '../css/referrer.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faFileCircleXmark, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { Decimals } from './common/function'

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';
import { useDispatch, useSelector } from 'react-redux';
import { changeType, checkMyInfo } from '../../store';

function Dashboard() {
    let Typemode = useSelector((state) => { return state.Typemode.value; });
    // let myInfo = useSelector((state) => { return state.MyInfo.value; });
    let dispatch = useDispatch()
    let navigator = useNavigate()

    const [btn, setBtn] = useState(false)
    const [myInfo, setMyInfo] = useState([])
    const [subR, setSubR] = useState([])
    const [subT, setSubT] = useState([])
    const [wallet, setWallet] = useState([])
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState("")
    const [settlementDate, setSettlementDate] = useState([])
    const [settlementTodayDate, setSettlementTodaytDate] = useState([])
    const [settlementYesterdayDate, setSettlementYesterdaytDate] = useState([])
    const [settlementLiveDate, setSettlementLivetDate] = useState([])
    const [sum, setSum] = useState([0, 0])

    useEffect(() => {
        DashboardData()
        WalletData()
        SettlementData()
        SettlementTodayData()
        SettlementLiveData()
    }, [])

    useEffect(() => {
        if (settlementDate?.length > 0) {
            let bn = 0
            let okx = 0
            for (let i = 0; i < settlementDate.length; i++) {
                let tmp = settlementDate[i]
                bn += Number(tmp.binance_income)
                okx += Number(tmp.okx_income)
            }
            setSum([bn, okx])
        }
    }, [settlementDate])

    const DashboardData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/dashboard/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setMyInfo(tmp.d.info && tmp.d.info[0] ? tmp.d.info[0] : [])
                setSubR(tmp.d?.sub ?? [])
                setSubT(tmp.d?.subT ?? [])
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const WalletData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/wallet', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setWallet(tmp.d && tmp.d[0] ? tmp.d[0] : [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/settlement', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSettlementDate(tmp?.d)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementTodayData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/settlement/today', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setSettlementTodaytDate(tmp.d.today[0])
                setSettlementYesterdaytDate(tmp.d.yesterday[0])
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementLiveData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/settlement/realtime', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setSettlementLivetDate(tmp?.d)
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <div className="App-box">
            <Header loca={`${myInfo?.broker ?? ""}`} type={0} />
            {
                btn
                    ?
                    <Modal setBtn={setBtn} onChangeModal={onChangeModal} setMessage={setMessage} />
                    :
                    ""
            }
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>{message}</div>
                    </div>
                    :
                    ""
            }
            {
                Typemode
                    ?
                    <div className='dashboard-1' onClick={() => setBtn(true)}>
                        <div className='dashboard-1-1'>레퍼럴 등록</div>
                        <div className='dashboard-1-2'><FontAwesomeIcon icon={faAngleRight} /></div>
                    </div>
                    :
                    <div className='dashboard-1'>
                        <div className='dashboard-1-3'>
                            <div className='dashboard-1-3-1'>CODE</div>
                            <div>{`${myInfo?.broker ?? ""}`}</div>
                        </div>
                        <CopyToClipboard text={myInfo?.broker ?? ""}>
                            <div className='code-btn btn' onClick={() => { onChangeModal(); setMessage("복사") }}>
                                복사
                                {/* <i className="fa-regular fa-copy click-copy"></i> */}
                            </div>
                        </CopyToClipboard>
                    </div>
            }

            <div className='dashboard-2'>
                <div className='dashboard-2-1'>자산정보</div>
                <div className='dashboard-6'>
                    <div className='dashboard-6-1'>
                        <img src='/img/binance_logo.png' style={{ width: "25px" }} />
                        <div className='dashboard-6-1-1'>
                            <div className='dashboard-6-1-2'>Binance</div>
                            <div className='dashboard-6-1-3'>{Number(wallet?.binance_free ?? 0) + Number(wallet?.binance_lock ?? 0)} <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-6-2'>
                        <div className='dashboard-6-2-1 btn' onClick={() => navigator(`/withdraw`)}>출금</div>
                    </div>
                </div>
                <div className='dashboard-6'>
                    <div className='dashboard-6-1'>
                        <img src='/img/okx_logo.png' style={{ width: "25px" }} />
                        <div className='dashboard-6-1-1'>
                            <div className='dashboard-6-1-2'>OKX</div>
                            <div className='dashboard-6-1-3'>{Number(wallet?.okx_free ?? 0) + Number(wallet?.okx_lock ?? 0)} <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-6-2'>
                        <div className='dashboard-6-2-1 btn' onClick={() => navigator(`/withdraw`)}>출금</div>
                    </div>
                </div>
            </div>
            <div className='dashboard-2'>
                <div className='dashboard-2-6'>
                    <div className='dashboard-2-1'>금일 정산액</div>
                    <div className='dashboard-2-2'>{Decimals(Number(settlementTodayDate?.binance_income ?? 0) + Number(settlementTodayDate?.okx_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-2-2-1 red'><FontAwesomeIcon icon={faCaretDown} /> {Decimals(Number(settlementYesterdayDate?.binance_income ?? 0) + Number(settlementYesterdayDate?.okx_income ?? 0)).toFixed(4)}</div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/settlement`)}>정산</div>
                    </div>
                </div>
                <div className='dashboard-2-3'>
                    <div className='dashboard-2-3-1'><img src='/img/binance_logo.png' style={{ width: "25px" }} />Binance</div>
                    <div className='dashboard-2-3-2'>
                        <div className='dashboard-2-3-2-1'>{Decimals(settlementTodayDate?.binance_income ?? 0).toFixed(4)} <span>USDT</span></div>
                        <div className='dashboard-2-3-2-2 red'><FontAwesomeIcon icon={faCaretDown} /> {Decimals(settlementYesterdayDate?.binance_income ?? 0).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
                <div className='dashboard-2-3'>
                    <div className='dashboard-2-3-1'><img src='/img/okx_logo.png' style={{ width: "25px" }} />OKX</div>
                    <div className='dashboard-2-3-2'>
                        <div className='dashboard-2-3-2-1'>{Decimals(settlementTodayDate?.okx_income ?? 0).toFixed(4)} <span>USDT</span></div>
                        <div className='dashboard-2-3-2-2 green'><FontAwesomeIcon icon={faCaretUp} /> {Decimals(settlementYesterdayDate?.okx_income ?? 0).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
                <div className='hr-dash'></div>
                <div className='dashboard-2-6'>
                    <div className='dashboard-2-1'>누적 정산액</div>
                    <div className='dashboard-2-2' style={{ paddingBottom: "0px", fontSize: "18px" }}>{Decimals(sum[0] + sum[1] ?? 0).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/menu/settlement`)}>정산내역</div>
                    </div>
                </div>
                <div className='dashboard-2-6' style={{ marginTop: "10px" }}>
                    <div className='dashboard-2-1'>실시간 정산액</div>
                    <div className='dashboard-2-2' style={{ paddingBottom: "0px", fontSize: "18px" }}>{Decimals(settlementLiveDate?.est_income ?? 0).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/menu/history`)}>거래내역</div>
                    </div>
                </div>
            </div>
            {
                Typemode
                    ?
                    <div className='dashboard-2'>
                        <div className='dashboard-2-1-1'>레퍼럴 현황</div>
                        <div className='dashboard-2-4' style={{ paddingRight: "10px" }}>
                            <div className='dashboard-2-4-3 op6'>레퍼러</div>
                            <div className='dashboard-2-4-4 op6'>하위 레퍼러 수</div>
                            <div className='dashboard-2-4-4 op6'>트레이더 수</div>
                        </div>
                        {
                            subR?.length > 0
                                ?
                                subR.map(function (a, i) {
                                    return (
                                        <ReferrerList key={i} item={a} />
                                    )
                                })
                                :
                                <div className='noData'>
                                    <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                </div>

                        }
                    </div>
                    :
                    <div className='dashboard-2'>
                        <div className='dashboard-2-1-1'>트레이더 현황</div>
                        <div className='dashboard-2-4'>
                            <div className='dashboard-2-4-1 op6'>순위</div>
                            <div className='dashboard-2-4-2 op6'>트레이더</div>
                            <div className='dashboard-2-4-2-1 op6'>거래량</div>
                        </div>
                        {
                            subT?.length > 0
                                ?
                                subT.map(function (a, i) {
                                    return (
                                        <TraderList key={i} item={a} no={i + 1} />
                                    )
                                })
                                :
                                <div className='noData'>
                                    <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                </div>

                        }
                    </div>
            }

            <Footer num={1} />
        </div>
    )
}

function ReferrerList({ item }) {
    return (
        <Link to={`/subReferrer/info/${item.broker_code}`} className='dashboard-2-4' style={{ paddingRight: "10px" }}>
            <div className='sub-arrow-right'>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className='dashboard-2-4-3'>{item.broker_code}</div>
            <div className='dashboard-2-4-4'>{item.sub_referrer_count}</div>
            <div className='dashboard-2-4-4'>{item.sub_trader_count}</div>
        </Link>
    )
}

function TraderList({ item, no }) {
    return (
        <div className='dashboard-2-4'>
            <div className='dashboard-2-4-1'>{no}</div>
            <div className='dashboard-2-4-2'>{item.id}</div>
            <div className='dashboard-2-4-2-1'>{item.volume}</div>
        </div>
    )
}


function Modal({ setBtn, onChangeModal, setMessage }) {
    const [uid, setUID] = useState("")
    const [otp, setOTP] = useState("")
    const [code, setCode] = useState("")
    const [rate, setRate] = useState("")
    const [state, setState] = useState(false)

    const onChangeUID = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setUID(onlyNumber)
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const onChangeCode = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "")
        setCode(onlyNumber)
    }

    const onChangeRate = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setRate(onlyNumber)
    }


    const onSendOTP = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/regist/referrer', {
            withCredentials: true,
            params: {
                uid: uid
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage("인증번호 발송")
                setState(true)
            }
            else {
                onChangeModal()
                setMessage(tmp.m)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/regist/referrer/check', {
            withCredentials: true,
            params: {
                uid: uid,
                certifyNumber: otp,
                referrerCode: code,
                rate: Number(rate) / 100
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage("등록완료")
                setTimeout(() => {
                    setBtn(false)
                }, 500)
            }
            else {
                onChangeModal()
                setMessage(tmp.m)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div className='modal' onClick={() => setBtn(false)}></div>
            <div className='modal-box-1'>
                <div className='modal-1'>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>레퍼럴 UID</div>
                        <div className='modal-2-1-1'>
                            <input value={uid} onChange={onChangeUID} maxLength={6} className='modal-2-1-input' />
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>인증번호</div>
                        <div className='modal-2-1-1'>
                            <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                            <div className='modal-2-1-btn' onClick={() => onSendOTP()}>발송</div>
                        </div>
                    </div>
                    {
                        state
                            ?
                            <>
                                <div className='modal-2-1'>
                                    <div className='modal-1-1-1'>레퍼럴 코드</div>
                                    <div className='modal-2-1-1'>
                                        <input value={code} onChange={onChangeCode} maxLength={8} className='modal-2-1-input' />
                                    </div>
                                </div>
                                <div className='modal-2-1'>
                                    <div className='modal-1-1-1'>요율(%)</div>
                                    <div className='modal-2-1-1'>
                                        <input value={rate} onChange={onChangeRate} maxLength={2} className='modal-2-1-input' />
                                    </div>
                                </div>
                            </>
                            :
                            ""
                    }
                    <div className={`modal-btn ${state ? "" : "disable"}`} onClick={() => onSubmit()}>등록하기</div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;