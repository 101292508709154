import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

import Header from '../../../Nav/header';
import Footer from '../../../Nav/footer';

import '../../css/login.css'
import '../../css/referrer.css'

function Meun() {
    let navigate = useNavigate()

    const Logout = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/logout', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                navigate('/')
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }


    return (
        <div className="App-box-1">
            <Header loca={"메뉴"} type={1} />
            <div className='menu'>
                <Link to={`/menu/profile`} className='menu-1'>
                    <div>내 정보</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/settlement`} className='menu-1'>
                    <div>정산 내역</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/history`} className='menu-1'>
                    <div>거래 내역</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/transfer`} className='menu-1'>
                    <div>송금 내역</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/withdraw`} className='menu-1'>
                    <div>출금 내역</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/setting`} className='menu-1'>
                    <div>환경 설정</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
            </div>
            <div className='login-3'>
                <div className='login-2-1 btn' onClick={() => Logout()}>로그아웃</div>
            </div>
            <Footer num={5} />
        </div>
    )
}

export default Meun;