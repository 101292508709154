import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFileCircleXmark } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';

import Footer from '../../../Nav/footer';

import '../../css/login.css'
import '../../css/referrer.css'

function Menu_Withdraw() {
    let navigator = useNavigate()
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

    const [withdrawDate, setWithdrawDate] = useState([])

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2023; // 시작 년도를 2023으로 설정
        const yearsArray = [];

        for (let i = startYear; i <= currentYear; i++) {
            yearsArray.push(i);
        }

        setYears(yearsArray);
    }, []);

    useEffect(() => {
        WithdrawData()
    }, [])

    const WithdrawData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/withdraw', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setWithdrawDate(tmp.d)
                console.log(tmp.d)
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="App-box-1">
            <div className='header white'>
                <div className='header-left'>
                    <Link to={`/menu`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </Link>
                </div>
                <div className='header-center'>
                    <div>출금 내역</div>
                </div>
            </div>
            <div className='menu-2'>
                <div className='menu-2-4'>
                    <div className='menu-2-4-1'>일시</div>
                    <div className='menu-2-4-1'>받은사람</div>
                    <div className='menu-2-4-1'>금액</div>
                    <div className='menu-2-4-1'>거래소</div>
                </div>
                {
                    withdrawDate?.length > 0
                        ?
                        withdrawDate.map(function (a, i) {
                            return (
                                <WithdrawItem key={i} item={a} />
                            )
                        })
                        :
                        <div className='noData'>
                            <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                        </div>

                }
            </div>

            <Footer num={5} />
        </div>
    )
}

function WithdrawItem({ item }) {
    return (
        <div className='menu-2-3-item'>
            <div className='menu-2-4-1'>{item.time}</div>
            <div className='menu-2-4-1'>{item.receiver}</div>
            <div className='menu-2-4-1'>{item.amt}</div>
            <div className='menu-2-4-1'>{item.ex}</div>
        </div>
    )
}

export default Menu_Withdraw;