import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'

function Withdraw() {
    let navigator = useNavigate()
    return (
        <div className="App-box-1">
            <Header loca={"출금"} type={1} />

            <Link to={`/authentication`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='login-1'>
                <div className='login-1-1'>거래소 선택</div>
                <div className='login-1-3'>
                    <Link to={`/withdraw/network`} state={{ ex: "Binance" }} className='login-1-3-1 btn'>
                        <div className='login-1-3-1-box'>
                            <img src='/img/binance_logo.png' />
                            <div>Binance</div>
                        </div>
                    </Link>
                    <Link to={`/withdraw/network`} state={{ ex: "OKX" }} className='login-1-3-1 btn'>
                        <div className='login-1-3-1-box'>
                            <img src='/img/okx_logo.png' />
                            <div>OKX</div>
                        </div>
                    </Link>
                </div>
            </div>

            <Footer num={4} />
        </div>
    )
}

export default Withdraw;