import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';
import { Decimals } from './common/function'

import moment from 'moment';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'
import '../css/calendar.css'

function Settlement() {
    let navigator = useNavigate()
    const today = new Date();
    const [date, setDate] = useState(today);
    const [activeStartDate, setActiveStartDate] = useState(new Date());

    const [settlementData, setSettlementData] = useState([])
    const [settlementTodayData, setSettlementTodaytData] = useState([])
    const [settlementMonthData, setSettlementMonthtData] = useState([])
    const [dateView, setDateView] = useState("month")
    const [sum, setSum] = useState([0, 0])

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };


    const handleTodayClick = () => {
        const today = new Date();
        setActiveStartDate(today);
        setDate(today);
    };

    useEffect(() => {
        SettlementTodayData()
        SettlementData()
    }, [])

    const SettlementTodayData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/settlement/today', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setSettlementTodaytData(tmp.d[0])
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/settlement', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSettlementData(tmp?.d)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (settlementData?.length > 0) {
            let bn = 0
            let okx = 0
            for (let i = 0; i < settlementData.length; i++) {
                let tmp = settlementData[i]
                bn += Number(tmp.binance_income)
                okx += Number(tmp.okx_income)
            }
            setSum([bn, okx])
        }
    }, [settlementData])

    useEffect(() => {
        if (settlementData?.length > 0 && dateView == "month") {
            let sumMonth = 0
            for (let i = 0; i < settlementData.length; i++) {
                let tmp = settlementData[i]
                if (`${tmp.fill_date.split('-')[0]}-${tmp.fill_date.split('-')[1]}` === moment(activeStartDate).format("YYYY-MM")) {
                    sumMonth += Number(tmp.binance_income) + Number(tmp.okx_income)
                }
            }
            setSettlementMonthtData(sumMonth)
        }
    }, [settlementData, activeStartDate])

    return (
        <div className="App-box">
            <Header loca={"정산"} type={0} />
            <div className='dashboard-3'>
                <div className='dashboard-3-1'>
                    <div className='dashboard-3-1-1'>금일 정산액</div>
                    <div className='dashboard-3-1-2'>{Decimals(Number(settlementTodayData?.binance_income ?? 0) + Number(settlementTodayData?.okx_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-3-1-3'>
                        <div className='dashboard-3-1-3-1'><img src='/img/binance_logo.png' className='logo-img' />Binance</div>
                        <div className='dashboard-3-1-3-2'>{Decimals(settlementTodayData?.binance_income ?? 0).toFixed(4)} </div>
                    </div>
                    <div className='dashboard-3-1-3'>
                        <div className='dashboard-3-1-3-1'><img src='/img/okx_logo.png' className='logo-img' />OKX</div>
                        <div className='dashboard-3-1-3-2'>{Decimals(settlementTodayData?.okx_income ?? 0).toFixed(4)} </div>
                    </div>
                </div>
                <div className='dashboard-3-2'>
                    <div className='dashboard-3-2-1'>
                        <div className='dashboard-3-2-1-1'>누적 정산액</div>
                        <div className='dashboard-3-2-1-2'>{Decimals(Number(sum[0] ?? 0) + Number(sum[1] ?? 0)).toFixed(4)} <span>USDT</span></div>
                    </div>
                    <div className='dashboard-3-2-1'>
                        <div className='dashboard-3-2-1-1'>월별 정산액</div>
                        <div className='dashboard-3-2-1-2'>{Decimals(settlementMonthData ?? 0).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
            </div>
            <div className='StyledCalendarWrapper'>
                <Calendar
                    value={date}
                    onChange={handleDateChange}
                    formatDay={(locale, date) => moment(date).format('D')}
                    formatYear={(locale, date) => moment(date).format('YYYY')}
                    formatMonthYear={(locale, date) => moment(date).format('YYYY. MM')}

                    calendarType="gregory"
                    showNeighboringMonth={false}
                    next2Label={null}
                    prev2Label={null}
                    minDetail="year"

                    // 오늘 날짜로 돌아오는 기능을 위해 필요한 옵션 설정
                    activeStartDate={activeStartDate === null ? undefined : activeStartDate}
                    onActiveStartDateChange={({ activeStartDate, view }) => {
                        setActiveStartDate(activeStartDate);
                        setDateView(view)
                    }}

                    // 오늘 날짜에 '오늘' 텍스트 삽입하고 출석한 날짜에 점 표시를 위한 설정
                    tileContent={({ date, view }) => {
                        let html = [];
                        if (view === "month") {
                            for (let i = 0; i < settlementData.length; i++) {
                                let tmp = settlementData[i]
                                if (tmp.fill_date.split(' ')[0] === moment(date).format("YYYY-MM-DD")) {
                                    html.push(<div className='StyledEX'>
                                        {
                                            Number(tmp.binance_income) > 0
                                                ?
                                                <div className='StyledEX-1'>{Decimals(tmp.binance_income).toFixed(4)}</div>
                                                :
                                                ""
                                        }
                                        {
                                            Number(tmp.okx_income) > 0
                                                ?
                                                <div className='StyledEX-2'>{Decimals(tmp.okx_income).toFixed(4)}</div>
                                                :
                                                ""
                                        }
                                    </div>);
                                }
                            }
                        }
                        return <>{html}</>;
                    }}
                />

                {/* 오늘 버튼 추가 */}
                <div className='StyledType'>
                    <div className='StyledType-1'>Binance</div>
                    <div className='StyledType-2'>OKX</div>
                </div>
                <div className='StyledDate' onClick={handleTodayClick}>오늘</div>
                <div className='StyledNote'>*자세한 정보는 정산내역에서 확인할 수 있습니다.</div>
            </div>

            <Footer num={3} />
        </div>
    )
}

export default Settlement