import './css/login.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

function Authentication() {
    let navigate = useNavigate()
    let location = useLocation()
    const [id, setId] = useState(location.state === null ? "" : location.state.id);
    const [otp, setOTP] = useState("")

    useEffect(() => {
        if (location.state === null) {
            navigate('/')
        }
        else {
            setId(location.state.id)
        }
    }, [location])

    const onChangeNumber = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            Check_Authentication()
        };
    };

    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const Check_Authentication = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/login/check', {
            withCredentials: true,
            params: {
                id: id,
                certifyNumber: otp
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                navigate(`/dashboard`)
                localStorage.setItem("login", true)
            }
            else {
                alert(tmp.m)
            }
        }).catch(error => {
            console.error(error);
        });

    }

    return (
        <div className="App-box-1">
            <Link to={`/`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='login-1'>
                <div className='login-1-1'>인증번호 확인</div>
                <input className='login-1-1-input' placeholder='인증번호를 입력해주세요.' value={otp} onChange={onChangeNumber} onKeyDown={handleKeyPress} ref={inputRef} />
            </div>

            <div className='login-2'>
                <div className='login-2-1 btn' onClick={() => Check_Authentication()}>로그인</div>
            </div>
        </div>
    )
}

export default Authentication;