import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'

function Withdraw_Amount() {
    let navigator = useNavigate();
    const location = useLocation();

    const [amount, setAmount] = useState("")
    const [ex, setEx] = useState(location.state === null ? "" : location.state.ex)
    const [network, setNetwork] = useState(location.state === null ? "" : location.state.network)
    const [address, setAddress] = useState(location.state === null ? "" : location.state.address)
    const [tag, setTag] = useState(location.state === null ? "" : location.state.tag)

    useEffect(() => {
        if (location.state === null) {
            navigator(`/withdraw`)
        }
    }, [location])

    const onChangeAmount = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setAmount(onlyNumber);
    }

    const onClickWithdraw = () => {
        if (ex == "binance") {
            // Binance_Withdraw()
        }
        else {

        }
        navigator(`/withdraw`)
    }

    const Binance_Withdraw = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/withdraw/binance', {
            withCredentials: true,
            network: network,
            address: address,
            addressTag: tag,
            amount: amount
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.c >= 0) {
                navigator(`/withdraw`)
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="App-box-1">
            <Link to={`/withdraw`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='login-1'>
                <div className='login-1-1'>수량 입력</div>
                <div className='withdraw-2' style={{ marginTop: "20px" }}>
                    <div className='withdraw-1-1'>
                        <input className='withdraw-1-2-input' value={amount} onChange={onChangeAmount} />
                    </div>
                    <div className='withdraw-1-2'>USDT</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-1'>거래소</div>
                    <div className='withdraw-1-2'>{ex}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-1'>네트워크</div>
                    <div className='withdraw-1-2'>{network.network}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-1'>주소</div>
                    <div className='withdraw-1-2'>{address}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-1'>태그</div>
                    <div className='withdraw-1-2'>{tag}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-1'>최소 수량</div>
                    <div className='withdraw-1-2'>{network.withdrawMin}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-1'>수수료</div>
                    <div className='withdraw-1-2'>{network.withdrawFee}</div>
                </div>
                <div className='withdraw-1-btn' onClick={() => onClickWithdraw()}>출금</div>
            </div>

            <Footer num={4} />
        </div>
    )
}

export default Withdraw_Amount;