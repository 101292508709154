import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCaretDown, faCaretUp, faAngleRight, faFileCircleXmark } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { Decimals } from './common/function'

import moment from 'moment';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'

function SubReferrer_Info() {
    const today = new Date();
    const [date, setDate] = useState(today);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [activeStartDate, setActiveStartDate] = useState(new Date());

    const [tab, setTab] = useState(false)
    const [subR, setSubR] = useState([])
    const [subT, setSubT] = useState([])
    const [sum, setSum] = useState([0, 0])
    const [refBtn, setRefBtn] = useState(true)
    const [traderBtn, setTraderBtn] = useState(true)

    const [settlementData, setSettlementData] = useState([])
    const [settlementList, setSettlementList] = useState([])
    const [settlementTodayData, setSettlementTodaytData] = useState([])
    const [settlementMonthData, setSettlementMonthtData] = useState([])
    const [dateView, setDateView] = useState("month")

    let { broker } = useParams();
    let navigate = useNavigate()

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const handleTodayClick = () => {
        const today = new Date();
        setActiveStartDate(today);
        setDate(today);
    };

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2023; // 시작 년도를 2023으로 설정
        const yearsArray = [];

        for (let i = startYear; i <= currentYear; i++) {
            yearsArray.push(i);
        }

        setYears(yearsArray);
    }, []);

    useEffect(() => {
        setTab(false)
        SubReferrerData()
        SettlementData()
    }, [broker])

    const SubReferrerData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/sub/info', {
            withCredentials: true,
            params: {
                subId: broker
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSubR(tmp.d?.sub ?? [])
                setSubT(tmp.d?.subT ?? [])
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/settlement', {
            withCredentials: true,
            params: {
                id: broker
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSettlementData(tmp?.d)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (settlementData?.length > 0) {
            let bn = 0
            let okx = 0
            for (let i = 0; i < settlementData.length; i++) {
                let tmp = settlementData[i]
                bn += Number(tmp.binance_income)
                okx += Number(tmp.okx_income)
            }
            setSum([bn, okx])
        }
    }, [settlementData])

    useEffect(() => {
        if (settlementData?.length > 0) {
            let sumMonth = 0
            let arr = []
            for (let i = 0; i < settlementData.length; i++) {
                let tmp = settlementData[i]
                if (`${Number(tmp.fill_date.split('-')[0])}-${Number(tmp.fill_date.split('-')[1])}` == `${selectedYear}-${selectedMonth}`) {
                    arr.push(tmp)
                    sumMonth += Number(tmp.binance_income) + Number(tmp.okx_income)
                }
                if (tmp.fill_date.split(' ')[0] === moment(new Date()).format("YYYY-MM-DD")) {
                    setSettlementTodaytData(tmp)
                }
            }
            setSettlementList(arr)
            setSettlementMonthtData(sumMonth)
        }
    }, [settlementData, selectedYear, selectedMonth])

    return (
        <div className="App-box">
            <div className='header'>
                <div className='header-left'>
                    <div onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </div>
                </div>
                <div className='header-center'>
                    <div>{broker}</div>
                </div>
            </div>
            <div className='sub-1'>
                <div className={`sub-1-2 ${tab ? "" : "on"}`} onClick={() => setTab(false)}>
                    <div className='sub-1-1-1'>정산 정보</div>
                </div>
                <div className={`sub-1-2 ${tab ? "on" : ""}`} onClick={() => setTab(true)}>
                    <div className='sub-1-1-1'>하위 정보</div>
                </div>
            </div>
            {
                tab
                    ?
                    <>
                        <div className='sub-2' onClick={() => setRefBtn(e => !e)}>
                            <div>레퍼럴</div>
                            <div>{subR?.length ?? 0} <FontAwesomeIcon icon={refBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            refBtn
                                ?
                                subR.map(function (a, i) {
                                    return (
                                        <ReferrerList key={i} item={a} />
                                    )
                                })
                                :
                                ""
                        }
                        <div className='sub-2' onClick={() => setTraderBtn(e => !e)}>
                            <div>트레이더</div>
                            <div>{subT?.length ?? 0} <FontAwesomeIcon icon={traderBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            traderBtn
                                ?
                                <div className='dashboard-2'>
                                    <div className='dashboard-2-4'>
                                        <div className='dashboard-2-4-1'>트레이더</div>
                                        <div className='dashboard-2-4-2'>거래량</div>
                                        <div className='dashboard-2-4-2-1'>가입일</div>
                                    </div>
                                    {
                                        subT?.length > 0
                                            ?
                                            subT.map(function (a, i) {
                                                return (
                                                    <ListItem key={i} item={a} />
                                                )
                                            })
                                            :
                                            <div className='noData'>
                                                <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                            </div>
                                    }
                                </div>
                                :
                                ""
                        }
                    </>
                    :
                    <>
                        <div className='dashboard-3'>
                            <div className='dashboard-3-1'>
                                <div className='dashboard-3-1-1'>금일 정산액</div>
                                <div className='dashboard-3-1-2'>{Decimals(Number(settlementTodayData?.binance_income ?? 0) + Number(settlementTodayData?.okx_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                                <div className='dashboard-3-1-3'>
                                    <div className='dashboard-3-1-3-1'><img src='/img/binance_logo.png' className='logo-img' />Binance</div>
                                    <div className='dashboard-3-1-3-2'>{Decimals(settlementTodayData?.binance_income ?? 0).toFixed(4)} </div>
                                </div>
                                <div className='dashboard-3-1-3'>
                                    <div className='dashboard-3-1-3-1'><img src='/img/okx_logo.png' className='logo-img' />OKX</div>
                                    <div className='dashboard-3-1-3-2'>{Decimals(settlementTodayData?.okx_income ?? 0).toFixed(4)} </div>
                                </div>
                            </div>
                            <div className='dashboard-3-2'>
                                <div className='dashboard-3-2-1'>
                                    <div className='dashboard-3-2-1-1'>누적 정산액</div>
                                    <div className='dashboard-3-2-1-2'>{Decimals(Number(sum[0] ?? 0) + Number(sum[1] ?? 0)).toFixed(4)} <span>USDT</span></div>
                                </div>
                                <div className='dashboard-3-2-1'>
                                    <div className='dashboard-3-2-1-1'>월별 정산액</div>
                                    <div className='dashboard-3-2-1-2'>{Decimals(settlementMonthData ?? 0).toFixed(4)} <span>USDT</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='dashboard-2'>
                            <div className='menu-2-2' style={{ marginTop: "0px" }}>
                                <select
                                    id="year"
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(Number(e.target.value))}
                                >
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}년
                                        </option>
                                    ))}
                                </select>

                                <select
                                    id="month"
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                >
                                    {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                                        <option key={month} value={month}>
                                            {month}월
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='menu-2-3'>
                                <div className='menu-2-3-1'>일시</div>
                                <div className='menu-2-3-2'>총 정산액</div>
                                <div className='menu-2-3-3'>거래소별 정산액</div>
                            </div>
                            {
                                settlementList?.length > 0
                                    ?
                                    settlementList.map(function (a, i) {
                                        return (
                                            <SettlementItem key={i} item={a} />
                                        )
                                    })
                                    :
                                    <div className='noData'>
                                        <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                    </div>
                            }
                        </div>
                        {/* <div className='StyledCalendarWrapper'>
                            <Calendar
                                value={date}
                                onChange={handleDateChange}
                                formatDay={(locale, date) => moment(date).format('D')}
                                formatYear={(locale, date) => moment(date).format('YYYY')}
                                formatMonthYear={(locale, date) => moment(date).format('YYYY. MM')}
                                calendarType="gregory"
                                showNeighboringMonth={false}
                                next2Label={null}
                                prev2Label={null}
                                minDetail="year"

                                // 오늘 날짜로 돌아오는 기능을 위해 필요한 옵션 설정
                                activeStartDate={activeStartDate === null ? undefined : activeStartDate}
                                onActiveStartDateChange={({ activeStartDate, view }) => {
                                    setActiveStartDate(activeStartDate);
                                    setDateView(view)
                                }}

                                // 오늘 날짜에 '오늘' 텍스트 삽입하고 출석한 날짜에 점 표시를 위한 설정
                                tileContent={({ date, view }) => {
                                    let html = [];
                                    if (view === "month") {
                                        for (let i = 0; i < settlementData.length; i++) {
                                            let tmp = settlementData[i]
                                            if (tmp.fill_date.split(' ')[0] === moment(date).format("YYYY-MM-DD")) {
                                                html.push(<div className='StyledEX'>
                                                    {
                                                        Number(tmp.binance_income) > 0
                                                            ?
                                                            <div className='StyledEX-1'>{Decimals(tmp.binance_income).toFixed(4)}</div>
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        Number(tmp.okx_income) > 0
                                                            ?
                                                            <div className='StyledEX-2'>{Decimals(tmp.okx_income).toFixed(4)}</div>
                                                            :
                                                            ""
                                                    }
                                                </div>);
                                            }
                                        }
                                    }
                                    return <>{html}</>;
                                }}
                            />

                            <div className='StyledType'>
                                <div className='StyledType-1'>Binance</div>
                                <div className='StyledType-2'>OKX</div>
                            </div>
                            <div className='StyledDate' onClick={handleTodayClick}>오늘</div>
                        </div> */}
                    </>
            }

            <Footer num={2} />
        </div>
    )
}

function SettlementItem({ item }) {
    let formatDate = item.fill_date?.split(' ')

    return (
        <div className='menu-2-4-item'>
            <div className='menu-2-3-1'>{formatDate[0]}</div>
            <div className='menu-2-3-2'>{(Number(item.binance_income) + Number(item.okx_income)).toFixed(8)}</div>
            <div className='menu-2-3-3'>
                <div className='menu-2-3-3-1'>
                    <div className='menu-2-3-3-2'>Binance</div>
                    <div className='menu-2-3-3-2'>{Number(item.binance_income).toFixed(8)}</div>
                </div>
                <div className='menu-2-3-3-1'>
                    <div className='menu-2-3-3-2'>OKX</div>
                    <div className='menu-2-3-3-2'>{Number(item.okx_income).toFixed(8)}</div>
                </div>
            </div>
        </div>
    )
}

function ReferrerList({ item }) {
    return (
        <Link to={`/subReferrer/info/${item.broker_code}`} state={{ broker: item.broker_code }} className='dashboard-2' >
            <div className='sub-arrow-right-1'>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className='sub-3'>
                <div className='sub-3-1 border-right'>
                    <div className='sub-3-1-1'>레퍼럴</div>
                    <div>{item.broker_code}</div>
                </div>
                <div className='sub-3-1 border-right'>
                    <div className='sub-3-1-1'>하위 레퍼럴</div>
                    <div>{item.sub_broker_count}</div>
                </div>
                <div className='sub-3-1'>
                    <div className='sub-3-1-1'>트레이더</div>
                    <div>{item.sub_trader_count}</div>
                </div>
            </div>
        </Link >
    )
}

function ListItem({ item }) {
    return (
        <div className='dashboard-2-4'>
            <div className='dashboard-2-4-1'>{item.id}</div>
            <div className='dashboard-2-4-2'>{Number(item.volume).toFixed(3)}</div>
            <div className='dashboard-2-4-2-1'>{item.regist_date}</div>
        </div>
    )
}

export default SubReferrer_Info;