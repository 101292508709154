import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';

import Footer from '../../../Nav/footer';

import '../../css/login.css'
import '../../css/referrer.css'
import { months } from 'moment';
import Page from '../../Page';

function Menu_History() {
    let navigator = useNavigate()
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedDay, setSelectedDay] = useState(new Date().getDate());
    const [daysInMonth, setDaysInMonth] = useState([]);

    const [check_bn, setCheck_bn] = useState(true)
    const [check_okx, setCheck_okx] = useState(true)
    const [page, setPage] = useState(1)
    const [tradingData, setTradingData] = useState([])
    const [tradingList, setTradingList] = useState([])

    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2023; // 시작 년도를 2023으로 설정
        const yearsArray = [];

        for (let i = startYear; i <= currentYear; i++) {
            yearsArray.push(i);
        }

        setYears(yearsArray);
    }, []);

    useEffect(() => {
        setDaysInMonth(Array.from({ length: getDaysInMonth(selectedYear, selectedMonth) }, (_, i) => i + 1));
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        TradingData()
    }, [selectedYear, selectedMonth, selectedDay])

    const TradingData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/menu/tradelist', {
            withCredentials: true,
            params: {
                year: selectedYear,
                month: selectedMonth,
                day: selectedDay
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setTradingData(tmp.d)
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const groupDataById = (data) => {
        const grouped = data.reduce((acc, current) => {
            if (!acc[current.trade_id]) {
                acc[current.trade_id] = [];
            }
            acc[current.trade_id].push(current);
            return acc;
        }, {});

        return Object.values(grouped);
    };

    useEffect(() => {
        let list = []

        if (tradingData) {
            const groupedData = groupDataById(tradingData);
            for (let i = 0; i < groupedData.length; i++) {
                let tmp = groupedData[i]
                let arr = []
                // let arr = [{
                //     "ex": "",
                //     "fee": "",
                //     "fill_time": "",
                //     "trade_id": "",
                //     "trader": "",
                //     "volume": "",
                //     "total_income": "",
                //     "b1": "",
                //     "b2": "",
                //     "b3": "",
                // }]
                for (let j = 0; j < tmp.length; j++) {
                    if (j === 0) {
                        arr.ex = tmp[j].ex
                        arr.fee = tmp[j].fee
                        arr.fill_time = tmp[j].fill_time
                        arr.trade_id = tmp[j].trade_id
                        arr.trader = tmp[j].trader
                        arr.volume = tmp[j].volume
                        arr.total_income = tmp[j].total_income
                    }
                    if (tmp[j].broker_grade == '1') arr.b1 = tmp[j].income ?? '0'
                    else if (tmp[j].broker_grade == '2') arr.b2 = tmp[j].income ?? '0'
                    else if (tmp[j].broker_grade == '3') arr.b3 = tmp[j].income ?? '0'
                }
                list.push(arr)
            }
            setTradingList(list)
        }
    }, [tradingData, check_bn, check_okx])

    return (
        <div className="App-box-1">
            <div className='header white'>
                <div className='header-left'>
                    <Link to={`/menu`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </Link>
                </div>
                <div className='header-center'>
                    <div>거래 내역</div>
                </div>
            </div>
            <div className='menu-2'>
                <div className='menu-2-2'>
                    <select
                        id="year"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(Number(e.target.value))}
                    >
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}년
                            </option>
                        ))}
                    </select>

                    <select
                        id="month"
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(Number(e.target.value))}
                    >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                            <option key={month} value={month}>
                                {month}월
                            </option>
                        ))}
                    </select>

                    <select
                        id="day"
                        value={selectedDay}
                        onChange={(e) => setSelectedDay(Number(e.target.value))}
                    >
                        {daysInMonth.map((day) => (
                            <option key={day} value={day}>
                                {day}일
                            </option>
                        ))}
                    </select>
                </div>
                <div className='menu-3'>
                    <div className={`menu-3-1 ${check_bn ? "on" : ""}`} onClick={() => setCheck_bn(e => !e)}>
                        <i className={`fa-regular ${check_bn ? "fa-circle-check" : "fa-circle"}`}></i> Binance
                    </div>
                    <div className={`menu-3-1 ${check_okx ? "on" : ""}`} onClick={() => setCheck_okx(e => !e)}>
                        <i className={`fa-regular ${check_okx ? "fa-circle-check" : "fa-circle"}`}></i> OKX
                    </div>
                </div>
                <div className='menu-2-scroll'>
                    <div className='menu-2-5'>
                        <div className='menu-2-4-1 table-sticky' style={{ width: "85px" }}>거래 ID</div>
                        <div className='menu-2-4-1' style={{ width: "60px" }}>거래시간</div>
                        <div className='menu-2-4-1' style={{ width: "65px" }}>거래소</div>
                        <div className='menu-2-4-1' style={{ width: "55px" }}>트레이더</div>
                        <div className='menu-2-4-1' style={{ width: "100px" }}>거래량</div>
                        <div className='menu-2-4-1' style={{ width: "100px" }}>수수료</div>
                        <div className='menu-2-4-1' style={{ width: "100px" }}>레퍼럴1</div>
                        <div className='menu-2-4-1' style={{ width: "100px" }}>레퍼럴2</div>
                        <div className='menu-2-4-1' style={{ width: "100px" }}>레퍼럴3</div>
                        <div className='menu-2-4-1' style={{ width: "100px" }}>총량</div>
                    </div>
                    <div className='menu-2-6'>
                        {
                            tradingList.map(function (a, i) {
                                if (check_bn && a.ex == "BINANCE" || check_okx && a.ex == "OKX") {
                                    return (
                                        <TradingItem key={i} item={a} />
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            <Footer num={5} />
        </div>
    )
}

function TradingItem({ item }) {
    let formatDate = item.fill_time.split(" ")
    return (
        <div className='menu-2-5-item'>
            <div className='menu-2-4-1 table-sticky' style={{ width: "85px" }}>{item.trade_id}</div>
            <div className='menu-2-4-1' style={{ width: "60px" }}>{formatDate[1] ?? ""}</div>
            <div className='menu-2-4-1' style={{ width: "65px" }}>{item.ex}</div>
            <div className='menu-2-4-1' style={{ width: "55px" }}>{item.trader}</div>
            <div className='menu-2-4-1' style={{ width: "100px" }}>{item.volume}</div>
            <div className='menu-2-4-1' style={{ width: "100px" }}>{item.fee}</div>
            <div className='menu-2-4-1' style={{ width: "100px" }}>{item.b1}</div>
            <div className='menu-2-4-1' style={{ width: "100px" }}>{item.b2}</div>
            <div className='menu-2-4-1' style={{ width: "100px" }}>{item.b3}</div>
            <div className='menu-2-4-1' style={{ width: "100px" }}>{item.total_income}</div>
        </div>
    )
}

const changeTime = (d) => {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

export default Menu_History;