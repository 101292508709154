import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFileCircleXmark, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';
import { Decimals } from '../common/function'

import Footer from '../../../Nav/footer';

import '../../css/login.css'
import '../../css/referrer.css'
import { useDispatch } from 'react-redux';

function Menu_Settlement() {
    let navigator = useNavigate()
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

    const [settlementData, setSettlementData] = useState([])
    const [sum, setSum] = useState([0, 0])


    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2023; // 시작 년도를 2023으로 설정
        const yearsArray = [];

        for (let i = startYear; i <= currentYear; i++) {
            yearsArray.push(i);
        }

        setYears(yearsArray);
    }, []);

    useEffect(() => {
        SettlementdData()
    }, [selectedYear, selectedMonth])

    const SettlementdData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/menu/settlement', {
            withCredentials: true,
            params: {
                year: selectedYear,
                month: selectedMonth
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setSettlementData(tmp.d)
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (settlementData?.length > 0) {
            let bn = 0
            let okx = 0
            for (let i = 0; i < settlementData.length; i++) {
                let tmp = settlementData[i]
                bn += Number(tmp.binance_income)
                okx += Number(tmp.okx_income)
            }
            setSum([bn, okx])
        }
    }, [settlementData])

    return (
        <div className="App-box-1">
            <div className='header white'>
                <div className='header-left'>
                    <Link to={`/menu`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </Link>
                </div>
                <div className='header-center'>
                    <div>정산 내역</div>
                </div>
            </div>
            <div className='menu-2'>
                <div className='dashboard-5'>
                    <div className='dashboard-2-6'>
                        <div className='dashboard-2-1'>{selectedMonth}월 누적 정산액</div>
                        <div className='dashboard-2-2'>{(sum[0] + sum[1]).toFixed(8) ?? 0} <span>USDT</span></div>
                        <div className='dashboard-2-2-1 red'><FontAwesomeIcon icon={faCaretDown} /> 0.00031232</div>
                    </div>
                    <div className='dashboard-2-3'>
                        <div className='dashboard-2-3-1'><img src='/img/binance_logo.png' style={{ width: "25px" }} />Binance</div>
                        <div className='dashboard-2-3-2'>
                            <div className='dashboard-2-3-2-1'>{(sum[0]).toFixed(8) ?? 0} <span>USDT</span></div>
                            <div className='dashboard-2-3-2-2 red'><FontAwesomeIcon icon={faCaretDown} /> 0.00412345 <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-2-3'>
                        <div className='dashboard-2-3-1'><img src='/img/okx_logo.png' style={{ width: "25px" }} />OKX</div>
                        <div className='dashboard-2-3-2'>
                            <div className='dashboard-2-3-2-1'>{(sum[1]).toFixed(8) ?? 0} <span>USDT</span></div>
                            <div className='dashboard-2-3-2-2 green'><FontAwesomeIcon icon={faCaretUp} /> 0.00112345 <span>USDT</span></div>
                        </div>
                    </div>
                </div>
                <div className='menu-2-2'>
                    <select
                        id="year"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(Number(e.target.value))}
                    >
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}년
                            </option>
                        ))}
                    </select>

                    <select
                        id="month"
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(Number(e.target.value))}
                    >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                            <option key={month} value={month}>
                                {month}월
                            </option>
                        ))}
                    </select>
                </div>
                <div className='menu-2-3'>
                    <div className='menu-2-3-1'>일시</div>
                    <div className='menu-2-3-2'>총 정산액</div>
                    <div className='menu-2-3-3'>거래소별 정산액</div>
                </div>
                {
                    settlementData?.length > 0
                        ?
                        settlementData.map(function (a, i) {
                            return (
                                <SettlementItem key={i} item={a} />
                            )
                        })
                        :
                        <div className='noData'>
                            <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                        </div>
                }
            </div>

            <Footer num={5} />
        </div>
    )
}

function SettlementItem({ item }) {
    let formatDate = item.date?.split('T')

    return (
        <div className='menu-2-4-item'>
            <div className='menu-2-3-1'>{formatDate[0]}</div>
            <div className='menu-2-3-2'>{(Number(item.binance_income) + Number(item.okx_income)).toFixed(8)}</div>
            <div className='menu-2-3-3'>
                <div className='menu-2-3-3-1'>
                    <div className='menu-2-3-3-2'>Binance</div>
                    <div className='menu-2-3-3-2'>{Number(item.binance_income).toFixed(8)}</div>
                </div>
                <div className='menu-2-3-3-1'>
                    <div className='menu-2-3-3-2'>OKX</div>
                    <div className='menu-2-3-3-2'>{Number(item.okx_income).toFixed(8)}</div>
                </div>
            </div>
        </div>
    )
}

export default Menu_Settlement;