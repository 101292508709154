import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faAngleRight, faFileCircleXmark, faChartSimple, faUsers, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'
import { useSelector } from 'react-redux';

function SubReferrer() {
    let Typemode = useSelector((state) => { return state.Typemode.value; });
    let navigator = useNavigate()
    const [btn, setBtn] = useState(false)
    const [btn1, setBtn1] = useState(false)
    const [subR, setSubR] = useState([])
    const [subT, setSubT] = useState([])

    const [refBtn, setRefBtn] = useState(true)
    const [traderBtn, setTraderBtn] = useState(true)

    const [subInfo, setSubInfo] = useState([])
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState("")

    useEffect(() => {
        DashboardData()
    }, [])

    const DashboardData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/dashboard/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSubR(tmp.d?.sub ?? [])
                setSubT(tmp.d?.subT ?? [])
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <div className="App-box">
            <Header loca={"하위 레퍼럴"} type={0} />
            {
                btn
                    ?
                    <Modal setBtn={setBtn} subInfo={subInfo} onChangeModal={onChangeModal} setMessage={setMessage} />
                    :
                    ""
            }
            {
                btn1
                    ?
                    <Modal1 setBtn1={setBtn1} subInfo={subInfo} onChangeModal={onChangeModal} setMessage={setMessage} />
                    :
                    ""
            }
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>{message}</div>
                    </div>
                    :
                    ""
            }
            <div className='sub-1'>
                <div className='sub-1-1'>
                    <div className='sub-1-1-1'><FontAwesomeIcon icon={faUsers} style={{ color: "gray", paddingRight: "5px" }} /> 레퍼럴</div>
                    <div>{subR?.length ?? 0}</div>
                </div>
                <div className='sub-1-1'>
                    <div className='sub-1-1-1'><FontAwesomeIcon icon={faChartSimple} style={{ color: "gray", paddingRight: "5px" }} /> 트레이더</div>
                    <div>{subT?.length ?? 0}</div>
                </div>
            </div>
            {
                Typemode
                    ?
                    <>
                        <div className='sub-2' onClick={() => setRefBtn(e => !e)}>
                            <div>레퍼럴</div>
                            <div>{subR?.length ?? 0} <FontAwesomeIcon icon={refBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            refBtn
                                ?
                                subR.map(function (a, i) {
                                    return (
                                        <ReferrerList key={i} item={a} setBtn={setBtn} setBtn1={setBtn1} setSubInfo={setSubInfo} />
                                    )
                                })
                                :
                                ""
                        }
                        <div className='sub-2' onClick={() => setTraderBtn(e => !e)}>
                            <div>트레이더</div>
                            <div>{subT?.length ?? 0} <FontAwesomeIcon icon={traderBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            traderBtn
                                ?
                                <div className='dashboard-2'>
                                    <div className='dashboard-2-4'>
                                        <div className='dashboard-2-4-1'>트레이더</div>
                                        <div className='dashboard-2-4-2'>거래량</div>
                                        <div className='dashboard-2-4-2-1'>가입일</div>
                                    </div>
                                    {
                                        subT?.length > 0
                                            ?
                                            subT.map(function (a, i) {
                                                return (
                                                    <ListItem key={i} item={a} />
                                                )
                                            })
                                            :
                                            <div className='noData'>
                                                <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                            </div>
                                    }
                                </div>
                                :
                                ""
                        }
                    </>
                    :
                    <>
                        <div className='sub-2' onClick={() => setTraderBtn(e => !e)}>
                            <div>트레이더</div>
                            <div>{subT?.length ?? 0} <FontAwesomeIcon icon={traderBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            traderBtn
                                ?
                                <div className='dashboard-2'>
                                    <div className='dashboard-2-4'>
                                        <div className='dashboard-2-4-1'>트레이더</div>
                                        <div className='dashboard-2-4-2'>거래량</div>
                                        <div className='dashboard-2-4-2-1'>가입일</div>
                                    </div>
                                    {
                                        subT?.length > 0
                                            ?
                                            subT.map(function (a, i) {
                                                return (
                                                    <ListItem key={i} item={a} />
                                                )
                                            })
                                            :
                                            <div className='noData'>
                                                <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                            </div>
                                    }
                                </div>
                                :
                                ""
                        }
                        <div className='sub-2' onClick={() => setRefBtn(e => !e)}>
                            <div>레퍼럴</div>
                            <div>{subR?.length ?? 0} <FontAwesomeIcon icon={refBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            refBtn
                                ?
                                subR.map(function (a, i) {
                                    return (
                                        <ReferrerList key={i} item={a} setBtn={setBtn} setBtn1={setBtn1} setSubInfo={setSubInfo} />
                                    )
                                })
                                :
                                ""
                        }

                    </>
            }


            <Footer num={2} />
        </div>
    )
}

function ReferrerList({ item, setBtn, setBtn1, setSubInfo }) {
    const [drop, setDrop] = useState(false)
    const NoneClick = (event) => {
        event.preventDefault();
    };

    return (
        <Link to={`/subReferrer/info/${item.broker_code}`} className='dashboard-2'>
            <div className='sub-arrow-right-1'>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className='sub-3'>
                <div className='sub-3-1 border-right'>
                    <div className='sub-3-1-1'>레퍼럴</div>
                    <div>{item.broker_code}</div>
                </div>
                <div className='sub-3-1 border-right'>
                    <div className='sub-3-1-1'>하위 레퍼럴</div>
                    <div>{item.sub_referrer_count}</div>
                </div>
                <div className='sub-3-1'>
                    <div className='sub-3-1-1'>트레이더</div>
                    <div>{item.sub_trader_count}</div>
                </div>
            </div>
            <div onClick={NoneClick} style={{ width: "100%" }}>
                <div className='sub-4-2' onClick={() => setDrop(e => !e)} style={{ marginBottom: drop ? "15px" : "0" }}>
                    <FontAwesomeIcon icon={drop ? faChevronUp : faChevronDown} />
                </div>
                {
                    drop
                        ?
                        <div className='sub-4' onClick={NoneClick}>
                            <div className='sub-4-1 btn' onClick={() => { setBtn(true); setSubInfo(item) }}>송금</div>
                            <div className='sub-4-1 btn' onClick={() => { setBtn1(true); setSubInfo(item) }}>배율 변경</div>
                        </div>
                        :
                        ""
                }
            </div>
        </Link >
    )
}

function ListItem({ item }) {
    return (
        <div className='dashboard-2-4'>
            <div className='dashboard-2-4-1'>{item.id}</div>
            <div className='dashboard-2-4-2'>{Number(item.volume).toFixed(3)}</div>
            <div className='dashboard-2-4-2-1'>{item.regist_date}</div>
        </div>
    )
}

function Modal({ setBtn, subInfo, onChangeModal, setMessage }) {
    const [select, setSelect] = useState(0)
    const [otp, setOTP] = useState("")
    const [cost, setCost] = useState("")
    const [state, setState] = useState(false)

    const onChangeCost = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setCost(onlyNumber)
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const onSendOTP = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/transfer', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage("인증번호 발송")
                setState(true)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmitTransfer = async () => {
        await axios.post('https://api.referral.bitket.io/api/v1/broker/user/transfer', {
            subId: subInfo?.broker_code,
            ex: select === 0 ? "binance" : "okx",
            amt: cost,
            certifyNumber: otp
        }, {
            withCredentials: true
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage("송금완료")
                setTimeout(() => {
                    setBtn(false)
                }, 500)
            }
            else {
                onChangeModal()
                setMessage(tmp.m)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div className='modal' onClick={() => setBtn(false)}></div>
            <div className='modal-box'>
                <div className='modal-1'>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>받는 사람</div>
                        <div className='modal-1-1-2'>{subInfo?.broker_code}</div>
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>거래소</div>
                        <div className='modal-1-1-2'>
                            <div className={`modal-1-1-2-1 ${select === 0 ? "on" : ""}`} onClick={() => setSelect(0)}>Binance</div>
                            /
                            <div className={`modal-1-1-2-1 ${select === 1 ? "on" : ""}`} onClick={() => setSelect(1)}>OKX</div>
                        </div>
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>송금 금액</div>
                        <div className='modal-1-1-2'>
                            <input value={cost} onChange={onChangeCost} className='modal-1-1-2-input' /> <span>USDT</span>
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>인증번호</div>
                        <div className='modal-2-1-1'>
                            <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                            <div className='modal-2-1-btn' onClick={() => onSendOTP()}>발송</div>
                        </div>
                    </div>
                    <div className={`modal-btn ${state ? "" : "disable"}`} onClick={() => onSubmitTransfer()}>송금하기</div>
                </div>
            </div>
        </>
    )
}

function Modal1({ setBtn1, subInfo, onChangeModal, setMessage }) {
    const [cost, setCost] = useState("")
    const [state, setState] = useState(0)
    const [otp, setOTP] = useState("")

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const onChangeCost = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setCost(onlyNumber)
    }

    const onSubmitUpdate = async () => {
        if (Number(cost) > 0) {
            await axios.get('https://api.referral.bitket.io/api/v1/broker/user/update/rate', {
                withCredentials: true,
                params: {
                    subId: subInfo?.broker_code,
                    rate: (Number(cost) / 100).toFixed(2)
                }
            }).then((response) => {
                let tmp = response?.data
                if (tmp?.c >= 0) {
                    onChangeModal()
                    setMessage("인증번호 발송")
                    setState(1)
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            alert("배율을 입력해주세요.")
        }
    }

    const onSubmitUpdateRate = async () => {
        if (Number(otp) > 0) {
            await axios.get('https://api.referral.bitket.io/api/v1/broker/user/update/rate', {
                withCredentials: true,
                params: {
                    subId: subInfo?.broker_code,
                    rate: (Number(cost) / 100).toFixed(2),
                    certifyNum: otp
                }
            }).then((response) => {
                let tmp = response?.data
                if (tmp?.c >= 0) {
                    onChangeModal()
                    setMessage("배율변경")
                    setTimeout(() => {
                        setBtn1(false)
                    }, 500)
                }
                else {
                    onChangeModal()
                    setMessage(tmp.m)
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            alert("인증번호를 입력해주세요.")
        }
    }

    const onClick = () => {
        if (state === 0) {
            onSubmitUpdate()
        }
        else {
            onSubmitUpdateRate()
        }
    }

    return (
        <>
            <div className='modal' onClick={() => setBtn1(false)}></div>
            <div className='modal-box'>
                <div className='modal-1'>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>레퍼럴</div>
                        <div className='modal-1-1-2'>{subInfo?.broker_code}</div>
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>기존 요율</div>
                        <div className='modal-1-1-2'>{Number(subInfo?.rebate_rate) * 100}%</div>
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>배율 변경</div>
                        <div className='modal-1-1-2'>
                            <input value={cost} onChange={onChangeCost} maxLength={2} className='modal-1-1-2-input' disabled={state === 0 ? false : true} /> <span>%</span>
                        </div>
                    </div>
                    {
                        state === 1
                            ?
                            <div className='modal-2-1'>
                                <div className='modal-1-1-1'>인증번호</div>
                                <div className='modal-2-1-1'>
                                    <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                                </div>
                            </div>
                            :
                            ""
                    }
                    <div className='modal-btn' onClick={() => onClick()}>{state === 0 ? "인증번호 발송" : "배율 변경"}</div>
                </div>
            </div>
        </>
    )
}

export default SubReferrer;