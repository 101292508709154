import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'

function Withdraw_Network() {
    let navigator = useNavigate();
    const location = useLocation();

    const [ex, setEx] = useState(location.state === null ? "" : location.state.ex)
    const [networkList, setNetworkList] = useState([])
    const [network, setNetwork] = useState("")
    const [selectNetwork, setSelectNetwork] = useState([])
    const [address, setAddress] = useState("")
    const [tag, setTag] = useState("")

    useEffect(() => {
        if (location.state === null) {
            navigator(`/withdraw`)
        }
    }, [location])

    const onChangeNetwork = (e) => {
        setNetwork(e.target.value);
        let va = networkList.filter((item) => item.network == e.target.value)
        setSelectNetwork(va[0])
    }

    const onChangeAddress = (e) => {
        setAddress(e.target.value);
    }

    const onChangeTag = (e) => {
        setTag(e.target.value);
    }

    useEffect(() => {
        NetworkData()
    }, [])

    const NetworkData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/withdraw/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                let a = tmp.d.data.filter((item) => item.coin == "USDT")
                if (a && a[0]) setNetworkList(a[0].networkList)
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onClickWithdraw = () => {
        if (network != "") {
            navigator(`/withdraw/amount`, {
                state: {
                    ex: ex,
                    network: selectNetwork,
                    address: address,
                    tag: tag
                }
            })
        }
    }

    return (
        <div className="App-box-1">
            <Link to={`/withdraw`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='login-1'>
                <div className='login-1-1'>입금 정보 입력</div>
                <div className='withdraw-1' style={{ marginTop: "20px" }}>
                    <div className='withdraw-1-1'>네트워크</div>
                    <div className='withdraw-1-2'>
                        <select className='withdraw-1-2-select' value={network} onChange={onChangeNetwork}>
                            <option value={""}>네트워크를 선택해주세요.</option>
                            {
                                networkList.map((option, index) => (
                                    <option key={index} value={option.network}>{option.network}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className='withdraw-1'>
                    <div className='withdraw-1-1'>주소</div>
                    <div className='withdraw-1-2'>
                        <input className='withdraw-1-2-input' value={address} onChange={onChangeAddress} />
                    </div>
                </div>
                <div className='withdraw-1'>
                    <div className='withdraw-1-1'>태그</div>
                    <div className='withdraw-1-2'>
                        <input className='withdraw-1-2-input' value={tag} onChange={onChangeTag} />
                    </div>
                </div>
                <div className='withdraw-1-btn' onClick={() => onClickWithdraw()}>다음</div>
            </div>

            <Footer num={4} />
        </div>
    )
}

export default Withdraw_Network;