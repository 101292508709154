import './nav.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';

function Header({ loca, type }) {
    let dark = useSelector((state) => { return state.Darkmode.value; });

    return (
        <div className={`header ${type === 1 ? "white" : ""}`}>
            <Link to={`/dashboard`} className='header-left'>
                <img src='/img/GOEX_small.png' />
                {/* <img src='/img/GOEX_logo_light.png' /> */}
            </Link>
            <div className='header-center'>
                <div>{loca}</div>
            </div>
            <div className='header-right'>
                <FontAwesomeIcon icon={faBell} />
            </div>
        </div>
    )
}

export default Header;