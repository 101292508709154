import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Header from '../../../Nav/header';
import Footer from '../../../Nav/footer';

import '../../css/login.css'
import '../../css/referrer.css'
import { useDispatch, useSelector } from 'react-redux';
import { changeDark, changeType } from '../../../store';

function Menu_Setting() {
    let Darkmode = useSelector((state) => { return state.Darkmode.value; });
    let Typemode = useSelector((state) => { return state.Typemode.value; });

    let dispatch = useDispatch()
    let navigator = useNavigate()

    const onChangeDarkmode = () => {
        dispatch(changeDark(!Darkmode))
    };

    const onChangeTypemode = () => {
        dispatch(changeType(!Typemode))
    };

    return (
        <div className="App-box-1">
            <div className='header white'>
                <div className='header-left'>
                    <Link to={`/menu`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </Link>
                </div>
                <div className='header-center'>
                    <div>환경 설정</div>
                </div>
            </div>
            <div className='menu-2'>
                <div className='menu-2-1'>
                    <div>테마</div>
                    <div className='menu-2-1-2'>
                        <div className={`toggle-box ${Darkmode ? 'toggled' : ''}`} onClick={onChangeDarkmode}>
                            {
                                Darkmode
                                    ?
                                    <span className={`toggle-box-on`}>라이트</span>
                                    :
                                    <span className={`toggle-box-off`}>다크</span>
                            }
                            <div className="toggle-box-btn slider"></div>
                        </div>
                    </div>
                </div>
                <div className='menu-2-1'>
                    <div>영업 유형</div>
                    <div className='menu-2-1-2'>
                        <div className={`toggle-box ${Typemode ? 'toggled' : ''}`} onClick={onChangeTypemode}>
                            {
                                Typemode
                                    ?
                                    <span className={`toggle-box-on`}>레퍼러</span>
                                    :
                                    <span className={`toggle-box-off`}>트레이더</span>
                            }
                            <div className="toggle-box-btn slider"></div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer num={5} />
        </div>
    )
}

export default Menu_Setting;