import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom'

import Login from './Pages/login';
import Authentication from './Pages/authentication';
import SelectType from './Pages/selectType';
import Dashboard from './Pages/Referrer/dashboard';
import Settlement from './Pages/Referrer/settlement';
import SubReferrer from './Pages/Referrer/subReferrer';
import SubReferrer_Info from './Pages/Referrer/subReferrer_info';
import Withdraw from './Pages/Referrer/withdraw';
import Withdraw_Network from './Pages/Referrer/withdraw_network';
import Withdraw_Amount from './Pages/Referrer/withdraw_amount';
import Meun from './Pages/Referrer/menu/menu';
import Menu_Profile from './Pages/Referrer/menu/menu_profile';
import Menu_Settlement from './Pages/Referrer/menu/menu_settlement';
import Menu_History from './Pages/Referrer/menu/menu_history';
import Menu_Withdraw from './Pages/Referrer/menu/menu_withdraw';
import Menu_Transfer from './Pages/Referrer/menu/menu_transfer';
import Menu_Setting from './Pages/Referrer/menu/menu_setting';
import { useEffect } from 'react';
import { changeDark, changeType } from './store';
import { useDispatch } from 'react-redux';

function App() {
  let dispatch = useDispatch()

  useEffect(() => {
    let obj = localStorage.getItem("typeMode")
    let obj1 = localStorage.getItem("darkMode")
    if (obj) {
      if (obj == "referrer") {
        dispatch(changeType(true))
      }
      else {
        dispatch(changeType(false))
      }
    }
    if (obj1) {
      if (obj1=="light") {
        dispatch(changeDark(true))
      }
      else {
        dispatch(changeDark(false))
      }
    }
  }, [])

  console.error = (message) => {
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/authentication" element={<Authentication />} />
        <Route path="/selectType" element={<SelectType />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settlement" element={<Settlement />} />
        <Route path="/subReferrer" element={<SubReferrer />} />
        <Route path="/subReferrer/info/:broker" element={<SubReferrer_Info />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/withdraw/network" element={<Withdraw_Network />} />
        <Route path="/withdraw/amount" element={<Withdraw_Amount />} />
        <Route path="/menu" element={<Meun />} />
        <Route path="/menu/profile" element={<Menu_Profile />} />
        <Route path="/menu/settlement" element={<Menu_Settlement />} />
        <Route path="/menu/history" element={<Menu_History />} />
        <Route path="/menu/transfer" element={<Menu_Transfer />} />
        <Route path="/menu/withdraw" element={<Menu_Withdraw />} />
        <Route path="/menu/setting" element={<Menu_Setting />} />





        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
