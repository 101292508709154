import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

import Header from '../../../Nav/header';
import Footer from '../../../Nav/footer';
import { useDispatch, useSelector } from 'react-redux';

import '../../css/login.css'
import '../../css/referrer.css'

function Menu_Profile() {
    let navigator = useNavigate()
    const [myInfo, setMyInfo] = useState([])

    useEffect(() => {
        DashboardData()
    }, [])

    const DashboardData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/user/get/menu/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setMyInfo(tmp.d && tmp.d[0] ? tmp.d[0] : [])
            }
            if (tmp?.c == -9999) {
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    let formatDate = myInfo?.regist_date?.split('T') ?? ["", ""]
    let formatDate1 = formatDate[1].split('.000Z') ?? ["", ""]

    return (
        <div className="App-box-1">
            <div className='header white'>
                <div className='header-left'>
                    <Link to={`/menu`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </Link>
                </div>
                <div className='header-center'>
                    <div>내 정보</div>
                </div>
            </div>
            <div className='menu-2' style={{paddingTop:"50px"}}>
                <div className='menu-2-1'>
                    <div className='bold'>레퍼러 코드</div>
                    <div>{myInfo?.broker ?? ""}</div>
                </div>
                <div className='menu-2-1'>
                    <div className='bold'>닉네임</div>
                    <div>{myInfo?.name ?? ""}</div>
                </div>
                <div className='menu-2-1'>
                    <div className='bold'>이메일</div>
                    <div>{myInfo?.email ?? ""}</div>
                </div>
                <div className='menu-2-1'>
                    <div className='bold'>모바일</div>
                    <div>{myInfo?.mobile ?? ""}</div>
                </div>
                <div className='menu-2-1'>
                    <div className='bold'>가입일</div>
                    <div>{formatDate[0]} {formatDate1[0]}</div>
                </div>
            </div>

            <Footer num={5} />
        </div>
    )
}

export default Menu_Profile;